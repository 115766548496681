import styles from "./MainLayout.module.scss";
import logo from "assets/images/app_logo.png";
import ContactForm from "contact/components/form/ContactForm";
import Social from "contact/components/social/Social";
import { ContactInformation } from "contact/types";
import Navbar from "navigation/navbar/Navbar";

const MainLayout = () => {
  return (
    <div className={styles.main_container}>
      <div>{/* <Navbar></Navbar> */}</div>
      <img src={logo} className={styles.logo}></img>
      <Social></Social>
      {/* <ContactForm
        handleSubmit={(values: ContactInformation) => {
          console.log(values);
        }}
      ></ContactForm> */}
    </div>
  );
};

export default MainLayout;
