import facebookLogo from "assets/images/facebook.png";
import instagramLogo from "assets/images/instagram.png";
import whatsappLogo from "assets/images/whatsapp.png";
import tiktokLogo from "assets/images/tiktok.png";
import styles from "contact/components/social/Social.module.scss";

const Social = () => {
  return (
    <div className={styles.main_container}>
      <a
        href="https://wa.me/+50670608370?text=Los%20vi%20en%20su%20sitio%20web.%20Me%20brindás%20más%20información."
        target="_blank"
      >
        <img src={whatsappLogo}></img>
      </a>
      <a href="https://www.facebook.com/BVAStoreCR" target="_blank">
        <img src={facebookLogo}></img>
      </a>
      <a href="https://www.instagram.com/bva.store.cr" target="_blank">
        <img src={instagramLogo}></img>
      </a>

      {/* <a href="https://www.facebook.com/BVAStoreCR" target="_blank">
        <img src={tiktokLogo}></img>
      </a> */}
    </div>
  );
};

export default Social;
